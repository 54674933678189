import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useFormik } from 'formik'
import * as yup from 'yup'
import './addContact.css'
import './CreateTemplateSubsection.css'

const CreateTemplateSubsection = ({
   isVisible,
   cancel,
   templateSubsectionEditModeData,
   onSubmit
}) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         subsectionName: templateSubsectionEditModeData?.name || ''
      },
      validationSchema: yup.object().shape({
         subsectionName: yup.string().required('Введите название подраздела')
      }),
      onSubmit: async ({ subsectionName }, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            if (subsectionName === templateSubsectionEditModeData?.name) return cancel()

            const data = templateSubsectionEditModeData
               ? { id: templateSubsectionEditModeData.subsection_id, name: subsectionName }
               : subsectionName
            await onSubmit(data)
            setStatus(null)
            formik.resetForm()
            cancel()
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   useEffect(() => {
      templateSubsectionEditModeData && formik.setValues({
         subsectionName: templateSubsectionEditModeData.name
      })
   }, [templateSubsectionEditModeData])

   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <form 
               className="add-contact-wrapper"
               onSubmit={formik.handleSubmit}
            >
               <div className="add-contact-header">
                  <div className="add-contact-header-text">{templateSubsectionEditModeData ? 'Редактирование подраздела' : 'Новый подраздел'}</div>
                  <div className="add-contact-close">
                     <button
                        className="add-contact-close-button"
                        type="button"
                        onClick={() => {
                           formik.resetForm()
                           cancel()
                        }}
                     >
                        <img src="/assets/controls/close.svg" alt="" />
                     </button>
                  </div>
               </div>
               {formik.status && <p className='create-template-subsection-status'>{formik.status}</p>}
               <div className="add-contact-form-control">
                  <label 
                     className={
                        formik.errors.subsectionName
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     {formik.errors.subsectionName
                        ? formik.errors.subsectionName
                        : 'Название подраздела'
                     }
                  </label>
                  <input
                     className={
                        formik.errors.subsectionName
                           ? 'add-contact-form-control-input-red'
                           : 'add-contact-form-control-input'
                     }
                     type="text"
                     placeholder="Введите название подраздела"
                     id='subsectionName'
                     name='subsectionName'
                     value={formik.values.subsectionName}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
               </div>
               <div className="add-contact-form-submit">
                  <button
                     className="add-contact-form-submit-button"
                     type="submit"
                     disabled={disableSubmitBtn}
                  >
                     {templateSubsectionEditModeData ? 'Обновить' : 'Создать подраздел'}
                  </button>
               </div>
            </form>
         </div>
      </CSSTransition>
   )
}

export default CreateTemplateSubsection