import useState from 'react-usestateref'
import React, { useEffect } from 'react'
import axios from 'axios'
import AddContact from '../modals/AddContact'
import SidebarContactCard from './SidebarContactCard'
const SidebarContactsList = ({
   toggleSidebarView,
   showAddContactModal,
   setShowAddContactModal,
   channelList,
   newContactInitiationParams,
   allowedChannel,
   setCurrentContact,
   selectedConversation,
   isManager,
   handleContactCardClick
}) => {
   const [contacts, setContacts] = useState([])
   const [contactsList, setContactsList] = useState([])
   const [nextContactCursor, setNextContactCursor] = useState()

   useEffect(() => {
      axios
         .get(`/api/v1/contact/get?limit=20`, { withCredentials: true })
         .then(function (response) {
            // handle success
            if (!response.data.data.error) {
               setNextContactCursor(response.data.data.next_cursor)
               setContacts(response.data.data.clients)
               setContactsList(response.data.data.clients)
            }
         })
         .catch(function (error) {
            // handle error
            console.log(error)
         })
   }, [])

   const loadNextContactCursor = () => {
      nextContactCursor &&
         axios({
            method: 'get',
            url: `/api/v1/contact/get?limit=20&cursor=${nextContactCursor}`,
            withCredentials: true,
         })
            .then((response) => {
               setNextContactCursor(response.data.data.next_cursor)
               let contactArray = response.data.data.clients
               setContacts((prevState) => {
                  return [...prevState, ...contactArray]
               })
               setContactsList((prevState) => {
                  return [...prevState, ...contactArray]
               })
            })
            .catch((err) => console.log(err))
   }

   const handleContactScroll = (e) => {
      if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight) {
         nextContactCursor && loadNextContactCursor()
      }
   }

   const handleInputSearch = (term) => {
      term
         ? setContactsList(
              contacts.filter((contact) =>
                 contact.name.toLowerCase().startsWith(term.toLowerCase())
              )
           )
         : setContactsList(contacts)
   }

   const [isShowDropdown, setIsShowDropdown] = useState(false)
   const toggleIsShowDropdown = () => {
      setIsShowDropdown(!isShowDropdown)
   }

   const findChannel = (channel) => {
      setContactsList(contacts.filter((item) => channel.id === item.channel_id))
   }

   const selectAllChannels = () => {
      setContactsList(contacts)
   }

   return (
      <>
         <div style={{ padding: '0 36px 0 36px', marginTop: '36px' }}>
            <div
               className="sidebar-header-search-field"
               style={{ marginBottom: '20px' }}
            >
               <img
                  className="sidebar-header-search-field-icon"
                  src={`/assets/sidebar/search.svg`}
                  alt="Lookout"
               />
               <input
                  className="sidebar-header-search-field-input"
                  type="text"
                  placeholder="Поиск"
                  onChange={(event) => handleInputSearch(event.target.value)}
               />
            </div>
         </div>
         <div style={{ padding: '0 36px 0 36px', marginBottom: '22px' }}>
            <div className="dropdown">
               <div className="dropdown-wrapper" style={{ width: '100%' }}>
                  <div
                     className="dropdown-current-item"
                     onClick={toggleIsShowDropdown}
                  >
                     <span className="dropdown-current-item_name">
                        Только выбранные
                     </span>
                     <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        {isShowDropdown ? (
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2197 8.21967C11.5126 7.92678 11.9874 7.92678 12.2803 8.21967L18.2803 14.2197C18.5732 14.5126 18.5732 14.9874 18.2803 15.2803C17.9874 15.5732 17.5126 15.5732 17.2197 15.2803L11.75 9.81066L6.28033 15.2803C5.98744 15.5732 5.51256 15.5732 5.21967 15.2803C4.92678 14.9874 4.92678 14.5126 5.21967 14.2197L11.2197 8.21967Z"
                              fill="#252525"
                           />
                        ) : (
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
                              fill="#252525"
                           />
                        )}
                     </svg>
                  </div>
                  {isShowDropdown && (
                     <div
                        className="dropdown-list"
                        style={{ zIndex: '9999', width: '328px' }}
                     >
                        <div
                           className="dropdown-list_wrapper"
                           style={{ padding: '20px 24px' }}
                        >
                           {
                              <ul className="dropdown-list_items">
                                 <li
                                    className="dropdown-list_item"
                                    style={{
                                       margin: '0',
                                       marginBottom: '16px',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                       selectAllChannels()
                                       setIsShowDropdown(false)
                                    }}
                                 >
                                    <div className="dropdown-list_item-block">
                                       Все каналы
                                    </div>
                                 </li>
                                 {channelList.map((item) => {
                                    return (
                                       <li
                                          className="dropdown-list_item"
                                          style={{
                                             margin: '0',
                                             marginBottom: '16px',
                                             cursor: 'pointer',
                                          }}
                                          key={item.id}
                                          onClick={() => {
                                             findChannel(item)
                                             setIsShowDropdown(false)
                                          }}
                                       >
                                          <div className="dropdown-list_item-block">
                                             {item.type + ' - ' + item.username}
                                          </div>
                                       </li>
                                    )
                                 })}
                              </ul>
                           }
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
         <div
            className="sidebar-list"
            onScroll={handleContactScroll}
            style={{
               height: 'calc(68vh - 80px)',
               display: toggleSidebarView ? 'block' : 'none',
            }}
         >
            <AddContact
               close={() => {
                  setShowAddContactModal(!showAddContactModal)
               }}
               isVisible={showAddContactModal}
               channelList={channelList.filter(
                  (channel) => channel.status === 'active'
               )}
               newContactInitiationParams={newContactInitiationParams}
               allowedChannel={allowedChannel}
            />
            {contactsList.map((contact, index) => (
               <SidebarContactCard
                  selectedConversation={selectedConversation}
                  isManager={isManager}
                  key={index}
                  cardInfo={contact}
                  onCardClick={handleContactCardClick}
               />
            ))}
            <div className="sidebar-list-add-button-block">
               <button
                  onClick={() => setShowAddContactModal(true)}
                  className="sidebar-list-add-contact-button"
               >
                  Добавить контакт
               </button>
            </div>
         </div>
      </>
   )
}

export default SidebarContactsList
