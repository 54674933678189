import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import './auth.css'
import ManagerAuth from './ManagerAuth'
import { useDispatch } from 'react-redux'
import { managerLogin, requestResetPassword, resetPassword } from '../store/slices/auth/thunk-creators'
import ForgotPasswordPage from './ForgotPasswordPage'
import routeNames from '../utils/consts/routeNames'
import ResetPasswordEmailSentPage from './ResetPasswordEmailSentPage'
import getIsResetPasswordTokenExpired from '../utils/getIsResetPasswordTokenExpired'
import ResetPasswordTokenExpiredPage from './ResetPasswordTokenExpiredPage'
import ResetPasswordPage from './ResetPasswordPage'

const pagesWithoutErrorAlert = ['/amo-login']

const Auth = ({ oAuthLink, isManager }) => {
   const search = useLocation().search
   const location = useLocation()
   const authError = new URLSearchParams(search).get('error')
   const [searchParams] = useSearchParams()
   const token = searchParams.get('token')
   const isTokenExpired = getIsResetPasswordTokenExpired(token)
   
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const isPageNeedAlertError = !!pagesWithoutErrorAlert.find((item) =>
      location.pathname.startsWith(item)
   )

   const [displayManagerLogin, setDisplayManagerLogin] = useState(true)
   const [isAmoLogin, setIsAmoLogin] = useState(false)
   const [isForgotPasswordPage, setIsForgotPasswordPage] = useState(location.pathname === routeNames.FORGOT_PASSWORD)
   const [isResetPasswordEmailSentPage, setIsResetPasswordEmailSentPage] = useState(location.pathname === routeNames.RESET_PASSWORD_EMAIL_SENT)
   const [isResetPasswordPage, setIsResetPasswordPage] = useState(location.pathname === routeNames.RESET_PASSWORD)
   const [isResetPasswordFailPage, setIsResetPasswordFailPage] = useState(location.pathname === routeNames.RESET_PASSWORD_FAIL)
   const [showResetPasswordSuccessNotification, setShowResetPasswordSuccessNotification] = useState(false)

   const handleToggle = () => {
      navigate(displayManagerLogin ? routeNames.MANAGER_LOGIN : routeNames.LOGIN)
      setDisplayManagerLogin((prev) => !prev)
   }

   const handleManagerLogin = async (authData) => {
      return await dispatch(managerLogin(authData))
   }

   const handleForgotPasswordClick = () => navigate(routeNames.FORGOT_PASSWORD)

   const handleReturnBack = () => navigate(-1)

   const handleForgotPasswordFormSubmit = async (data) => {
      try {
         await dispatch(requestResetPassword(data))
         navigate(routeNames.RESET_PASSWORD_EMAIL_SENT)     
      } catch (err) {
         return Promise.reject(err)
      }
   }

   const handleResetPasswordFormSubmit = async (data) => {
      try {
         await dispatch(resetPassword(data))
         navigate(routeNames.MANAGER_LOGIN)
         setShowResetPasswordSuccessNotification(true)     
      } catch (err) {
         return Promise.reject(err)
      }
   }

   useEffect(() => {
      if (location.pathname === '/') navigate(routeNames.LOGIN)
   }, [])

   useEffect(() => {
      if (showResetPasswordSuccessNotification) setTimeout(() => setShowResetPasswordSuccessNotification(false), 5000)
   }, [showResetPasswordSuccessNotification])

   useEffect(() => {
      if (authError && !isPageNeedAlertError) {
         window.alert(authError)
      }
   }, [authError])
   
   useEffect(() => {
      if (location !== null && location.pathname !== null) {
         if (location.pathname.startsWith('/amo-login')) {
            setIsAmoLogin(true)
            setDisplayManagerLogin(false)
         }
      }
   }, [])
   
   useEffect(() => {
      setIsForgotPasswordPage(false)
      setIsResetPasswordEmailSentPage(false)
      setIsResetPasswordPage(false)
      setIsResetPasswordFailPage(false)
      switch (location.pathname) {
         case routeNames.FORGOT_PASSWORD:
            setIsForgotPasswordPage(true)
            break
         case routeNames.RESET_PASSWORD_EMAIL_SENT:
            setIsResetPasswordEmailSentPage(true)
            break
         case routeNames.RESET_PASSWORD:
            setIsResetPasswordPage(true)
            break
         case routeNames.RESET_PASSWORD_FAIL:
            setIsResetPasswordFailPage(true)
            break
         case routeNames.MANAGER_LOGIN:
            setDisplayManagerLogin(false)
            break
         default:
            setDisplayManagerLogin(true)
      }
   }, [location.pathname])

   useEffect(() => {
      if (token && isTokenExpired) navigate(routeNames.RESET_PASSWORD_FAIL)
   }, [token, isTokenExpired])

   return isForgotPasswordPage ? (
      <ForgotPasswordPage
         onSubmit={handleForgotPasswordFormSubmit}
         onReturnBack={handleReturnBack}
      />
   ) : isResetPasswordEmailSentPage ? (
      <ResetPasswordEmailSentPage />
   ) : isResetPasswordFailPage ? (
      <ResetPasswordTokenExpiredPage />
   ) : isResetPasswordPage ? (
      <ResetPasswordPage
         token={token}
         onSubmit={handleResetPasswordFormSubmit}
      />
   ) : displayManagerLogin ? (
      <div className="auth">
         <div className="login-card">
            <div className="login-card-wrapper">
               <div className="login-card-header-wrapper">
                  <h3
                     className="login-card-header-text"
                     style={{ textAlign: 'center' }}
                  >
                     Веб-Мессенджер
                  </h3>
               </div>
               <div className="login-card-body-wrapper">
                  <p
                     className="login-card-body-text"
                     style={{ textAlign: 'center' }}
                  >
                     Для авторизации/регистрации в мессенджере необходимо
                     авторизоваться через Ваш аккаунт в приложении i2crm
                  </p>
               </div>
               <div className="login-card-button-wrapper">
                  <a href={oAuthLink} style={{ textDecoration: 'none' }}>
                     <button className="login-card-button">
                        Войти через i2crm
                     </button>
                  </a>
                  <button
                     className="login-card-manager-button"
                     onClick={handleToggle}
                  >
                     Войти для менеджера
                  </button>
               </div>
            </div>
         </div>
      </div>
   ) : (
      <ManagerAuth
         handleToggle={handleToggle}
         isManager={isManager}
         isAmoLogin={isAmoLogin}
         authError={authError}
         showResetPasswordSuccessNotification={showResetPasswordSuccessNotification}
         onLogin={handleManagerLogin}
         onForgotPassword={handleForgotPasswordClick}
      />
   )
}

export default Auth
