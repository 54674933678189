import React, { useEffect, useRef } from 'react'
import useState from 'react-usestateref'
import './mainbar.css'
import Previewer from '../modals/Previewer'
import EmojiPicker from './EmojiPicker'
import Dialogs from './Dialogs'
import MessagesService from '../../services/messagesService'
import Loader from '../Loader'
import DialogSystemMessage from '../DialogSystemMessage'
import TemplateList from '../TemplateList'

const MainbarConversations = ({
   selectedConversation,
   messages,
   selectedMessages,
   setNextMessagesCursor,
   messagesLoader,
   setMessagesLoader,
   showTemplate,
   setShowTemplate,
   templateMessages,
   setSelectTemplate,
   setCommentReplySection,
   commentReplySection,
   channelStatus,
   setMessages,
   showEmoji,
   setShowEmoji,
   setChosenEmoji,
   autoReadStatus,
   userId,
   fetchDialogData,
   setInstagramConvoFilter,
   setUnreadMessagesIds,
   handleMessageStatusClick,
   handleReplyMessageClick,
   handleEditMessageClick,
   handleMessageSelect,
   handleForwardClick,
   selectedRepliedMessage,
   setSelectedRepliedMessage,
   getNextCursorDialogList,
   templates,
   templateSubsections
}) => {
   const [imagePreviewModal, setImagePreviewModal] = useState(false)
   const [imagePreviewLink, setImagePreviewLink, imagePreviewLinkRef] =
      useState(null)
   const [imagePreviewType, setImagePreviewType, imagePreviewTypeRef] =
      useState(null)
   const [imagePreviewId, setImagePreviewId, imagePreviewIdRef] = useState(null)
   const [imagePreviewThumb, setImagePreviewThumb] = useState(null)
   const [disableScroll, setDisableScroll] = useState(false)

   const handleScroll = async (e) => {
      if (
         e.target.clientHeight - e.target.scrollHeight + 35 >
         e.target.scrollTop && !disableScroll
      ) {
         setDisableScroll(true)
         await getNextCursorDialogList()
         setDisableScroll(false)
      }
   }

   const handleTemplateSelect = (template) => {
      setSelectTemplate(template)
      setShowTemplate(false)
   }

   useEffect(() => {
      showTemplate && setShowEmoji(false)
      showEmoji && setShowTemplate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [showEmoji, showTemplate])

   return (
      <div
         id='messages-list' 
         className="mainbar-conversations"
         onScroll={handleScroll}
      >
         {imagePreviewModal ? (
            <Previewer
               close={() => {
                  setImagePreviewModal(false)
               }}
               imagePreviewModal={imagePreviewModal}
               imagePreviewLink={imagePreviewLink}
               imagePreviewType={imagePreviewType}
               imagePreviewId={imagePreviewId}
               selectedConversation={selectedConversation}
               setImagePreviewLink={setImagePreviewLink}
               setImagePreviewModal={setImagePreviewModal}
               setImagePreviewType={setImagePreviewType}
               setImagePreviewId={setImagePreviewId}
               imagePreviewLinkRef={imagePreviewLinkRef}
               imagePreviewIdRef={imagePreviewIdRef}
               imagePreviewTypeRef={imagePreviewTypeRef}
               imagePreviewThumb={imagePreviewThumb}
               setImagePreviewThumb={setImagePreviewThumb}
            />
         ) : (
            <></>
         )}
         {showTemplate && (
            <TemplateList
               templates={templates}
               templateSubsections={templateSubsections}
               handleCloseTemplateList={() => setShowTemplate(false)}
               handleTemplateSelect={handleTemplateSelect}
            />
         )}
         {showEmoji && (
            <div className="mainbar-chat-emoji-box">
               <EmojiPicker setChosenEmoji={setChosenEmoji} />
            </div>
         )}
         <Dialogs
            messages={messages}
            selectedMessages={selectedMessages}
            setImagePreviewLink={setImagePreviewLink}
            setImagePreviewModal={setImagePreviewModal}
            setImagePreviewType={setImagePreviewType}
            setCommentReplySection={setCommentReplySection}
            autoReadStatus={autoReadStatus}
            setImagePreviewId={setImagePreviewId}
            setImagePreviewThumb={setImagePreviewThumb}
            fetchDialogData={fetchDialogData}
            setUnreadMessagesIds={setUnreadMessagesIds}
            handleMessageStatusClick={handleMessageStatusClick}
            handleReplyMessageClick={handleReplyMessageClick}
            selectedRepliedMessage={selectedRepliedMessage}
            setSelectedRepliedMessage={setSelectedRepliedMessage}
            handleEditMessageClick={handleEditMessageClick}
            handleMessageSelect={handleMessageSelect}
            handleForwardClick={handleForwardClick}
         />
         <DialogSystemMessage />
      </div>
   )
}

export default MainbarConversations
