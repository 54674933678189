import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './managerMainbar.css'
import WarningModal from '../modals/WarningModal'
import ManagerPermissionList from './ManagerPermissionList'
import NotificationModal from '../modals/NotificationModal'
import BasicModal from '../modals/BasicModal'
import { useAppNotification } from '../../hooks'
import { managerStatuses, notificationsData } from '../../utils/consts'
import EditManagerForm from '../EditManagerForm'
import ManagerPermission from './ManagerPermission'

const ManagerMainbar = ({
   managerCardClick,
   addManagerPage,
   tag,
   status,
   channelList,
   handleEditManagerFormSubmit,
   handleToggleManagerActivation,
   handleDeleteManager,
   onUpdateManagerIsAllowedToEditDialog,
   onUpdateIsContactInfoHidden,
   onUpdateIsContactListUnloadingAllowed
}) => {
   const location = useLocation()
   const navigate = useNavigate()

   const isPermissionsPage = location.state?.isPermissionsPage

   const [managerId, setManagerId] = useState('')
   const [managerUsername, setManagerUsername] = useState('')
   const [managerPassword, setManagerPassword] = useState('')
   const [managerEmail, setManagerEmail] = useState('')
   const [managerPermission, setManagerPermission] = useState({})
   const [forceRender, setForceRender] = useState(1)

   const [managerChannelsAllows, setManagerChannelsAllows] = useState([])
   const [isAllChannelsAllow, setIsAllChannelsAllow] = useState(false)

   const [managerTagsAllows, setManagerTagAllows] = useState([])
   const [isAllTagsAllow, setIsAllTagsAllow] = useState(false)

   const [managerStatusAllows, setManagerStatusAllows] = useState([])
   const [isAllStatusesAllow, setIsAllStatusesAllow] = useState(false)

   const [allDialogsWithoutActions, setAllDialogsWithoutActions] =
      useState(false)
   const toggleAllDialogsWithoutActions = () => {
      setAllDialogsWithoutActions(!allDialogsWithoutActions)
   }

   const [showWarningDeleteModal, setShowWarningDeleteModal] = useState(false)
   const toggleShowWarningDeleteModal = () => {
      setShowWarningDeleteModal(!showWarningDeleteModal)
   }
   const [currentManagerPage, setCurrentManagerPage] = useState(isPermissionsPage ? 2 : 1)
   const toggleManagerPage = (page) => {
      setCurrentManagerPage(page)
   }

   const [notification, showNotification, closeNotification] = useAppNotification()

   const [isShowAddPhotoModal, setIsShowAddPhotoModal] = useState(false)
   const toggleShowAddPhotoModal = () => {
      setIsShowAddPhotoModal(!isShowAddPhotoModal)
   }
   const [addedPhoto, setAddedPhoto] = useState(null)
   const [isEditDialogAllowed, setIsEditDialogAllowed] = useState(addManagerPage?.edit_dialog_allowed)
   const [isContactInfoHidden, setIsContactInfoHidden] = useState(addManagerPage?.hide_client_identity)
   const [isContactsListUnloadAllowed, setIsContactsListUnloadAllowed] = useState(addManagerPage?.unload_contact_list)
   const isManagerActive = addManagerPage?.status === managerStatuses.active
   
   const handleToggleIsContactInfoHidden = () => setIsContactInfoHidden(isHidden => !isHidden)

   const handleToggleIsEditDialogAllowed = () => setIsEditDialogAllowed(isAllowed => !isAllowed)

   const handleToggleIsContactsListUnloadAllowed = () => setIsContactsListUnloadAllowed(isAllowed => !isAllowed)

   useEffect(() => {
      if (isPermissionsPage) {
         setCurrentManagerPage(isPermissionsPage ? 2 : 1)
         navigate(location.pathname, { state: null })
      }
   }, [isPermissionsPage])

   useEffect(() => {
      let tempObj = managerCardClick && JSON.parse(managerCardClick.rights)
      managerCardClick &&
         (managerCardClick.rights !== null
            ? setManagerPermission({
                 dialog_create:
                    tempObj.dialog_status &&
                    tempObj.dialog_status.create &&
                    tempObj.dialog_status.create
                       ? true
                       : false,
                 dialog_edit:
                    tempObj.dialog_status &&
                    tempObj.dialog_status.edit &&
                    tempObj.dialog_status.edit
                       ? true
                       : false,
                 dialog_delete:
                    tempObj.dialog_status &&
                    tempObj.dialog_status.delete &&
                    tempObj.dialog_status.delete
                       ? true
                       : false,
                 dialog_manage:
                    tempObj.dialog_status &&
                    tempObj.dialog_status.manage &&
                    tempObj.dialog_status.manage
                       ? true
                       : false,
                 tag_create:
                    tempObj.dialog_tags &&
                    tempObj.dialog_tags.create &&
                    tempObj.dialog_tags.create
                       ? true
                       : false,
                 tag_edit:
                    tempObj.dialog_tags &&
                    tempObj.dialog_tags.edit &&
                    tempObj.dialog_tags.edit
                       ? true
                       : false,
                 tag_delete:
                    tempObj.dialog_tags &&
                    tempObj.dialog_tags.delete &&
                    tempObj.dialog_tags.delete
                       ? true
                       : false,
                 tag_manage:
                    tempObj.dialog_tags &&
                    tempObj.dialog_tags.manage &&
                    tempObj.dialog_tags.manage
                       ? true
                       : false,
              })
            : setManagerPermission({
                 dialog_create: false,
                 dialog_edit: false,
                 dialog_delete: false,
                 dialog_manage: false,
                 tag_create: false,
                 tag_edit: false,
                 tag_delete: false,
                 tag_manage: false,
              }))
   }, [managerCardClick, managerCardClick.rights])

   const checkValidity = (isNew) => {
      if (!managerUsername) return false
      if (!managerPassword) return false
      if (!managerEmail) return false
      if (!isNew && !managerId) return false
      return true
   }

   const handleAddManagerButton = () => {
      save(true)
   }

   const handleUpdateManagerButton = () => {
      save(false)
   }

   const save = (isNew) => {
      if (checkValidity(isNew)) {
         var bodyFormData = new FormData()
         let url = isNew
            ? `api/v1/manager/register`
            : `api/v1/manager/update?id=` + managerId
         bodyFormData.append('email', managerEmail)
         bodyFormData.append('password', managerPassword)
         bodyFormData.append('username', managerUsername)
         axios({
            method: 'post',
            url: url,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
         })
            .then(function (response) {
               if (response.data.error) {
                  let errorArray = Object.keys(response.data.data).map(
                     function (key) {
                        return response.data.data[key]
                     }
                  )
                  let errorDisplay = ''
                  errorArray.forEach((element) => {
                     errorDisplay = errorDisplay + element + '\n'
                  })
                  alert(errorDisplay)
               } else {
                  alert(
                     isNew
                        ? 'Менеджер успешно добавлен.'
                        : 'Менеджер успешно изменён.'
                  )
                  window.location.reload(false)
               }
            })
            .catch(function (err) {
               console.log(err)
               alert('Произошла ошибка...')
            })
      } else {
         alert('Все поля обязательны к заполнению!')
      }
   }

   const handlePermissionToggle = (permission) => {
      let tempObj = managerPermission
      tempObj[permission] = !managerPermission[permission]
      setManagerPermission(tempObj)
      setForceRender((prev) => prev + 1)
   }

   const generateLinkForStatusPermission = () => {
      let generatedLink = ''
      if (managerPermission.dialog_create) generatedLink += '"create":true'
      if (managerPermission.dialog_edit)
         generatedLink += managerPermission.dialog_create
            ? ', "edit":true'
            : '"edit":true'
      if (managerPermission.dialog_manage)
         generatedLink +=
            managerPermission.dialog_edit || managerPermission.dialog_create
               ? ', "manage":true'
               : '"manage":true'
      if (managerPermission.dialog_delete)
         generatedLink +=
            managerPermission.dialog_edit ||
            managerPermission.dialog_create ||
            managerPermission.dialog_edit
               ? ', "delete":true'
               : '"delete":true'
      if (generatedLink !== '') {
         generatedLink = '"dialog_status":{' + generatedLink + '}'
      } else {
         generatedLink = ''
      }
      return generatedLink
   }

   const generateLinkForTagsPermission = () => {
      let generatedLink = ''
      if (managerPermission.tag_create) generatedLink += '"create":true'
      if (managerPermission.tag_edit)
         generatedLink += managerPermission.tag_create
            ? ', "edit":true'
            : '"edit":true'
      if (managerPermission.tag_manage)
         generatedLink +=
            managerPermission.tag_edit || managerPermission.tag_create
               ? ', "manage":true'
               : '"manage":true'
      if (managerPermission.tag_delete)
         generatedLink +=
            managerPermission.tag_edit ||
            managerPermission.tag_create ||
            managerPermission.tag_edit
               ? ', "delete":true'
               : '"delete":true'
      if (generatedLink !== '') {
         generatedLink = '"dialog_tags":{' + generatedLink + '}'
      } else {
         generatedLink = ''
      }
      return generatedLink
   }

   const handleManagerPermissionChange = () => {
      let generatedData
      if (
         generateLinkForTagsPermission() !== '' &&
         generateLinkForStatusPermission() !== ''
      ) {
         generatedData = `{${generateLinkForTagsPermission()}, ${generateLinkForStatusPermission()}}`
      } else if (generateLinkForStatusPermission() !== '') {
         generatedData = `{${generateLinkForStatusPermission()}}`
      } else if (generateLinkForTagsPermission() !== '') {
         generatedData = `{${generateLinkForTagsPermission()}}`
      } else {
         generatedData = `{}`
      }
      axios
         .post(
            `/api/v1/manager/mass-permission?manager=${managerCardClick.id}`,
            {
               // eslint-disable-next-line no-useless-escape
               data: generatedData,
            }
         )
         .then(function (response) {
            if (response.data.error) {
               alert('Произошла ошибка...')
            } else {
               alert('Права менеджера изменены.')
            }
         })
         .catch(function (err) {
            console.log(err)
            alert('Произошла ошибка...')
         })
   }

   const activeToggleButton = {
      backgroundColor: 'white',
      marginLeft: 'auto',
   }

   const inActiveToggleButton = {
      backgroundColor: 'white',
      marginRight: 'auto',
   }

   useEffect(() => {
      if (addManagerPage === 0) {
         setManagerEmail('')
         setManagerUsername('')
      } else {
         setManagerId(addManagerPage.id)
         setManagerEmail(addManagerPage.email)
         setManagerUsername(addManagerPage.username)

         setManagerChannelsAllows(addManagerPage.allowed_channel)
         setIsAllChannelsAllow(addManagerPage.all_channels_available)
         if (addManagerPage.all_channels_available) {
            addAllChannelsPermissions()
         }

         setManagerTagAllows(addManagerPage.allowed_tag)
         setIsAllTagsAllow(tag.length > 0 && addManagerPage?.allowed_tag?.length === tag.length)
         if (addManagerPage.all_tags_available) {
            addAllTagsPermissions()
         }

         setManagerStatusAllows(addManagerPage.allowed_status)
         setIsAllStatusesAllow(addManagerPage.all_status_available)
         if (addManagerPage.all_status_available) {
            addAllStatusPermissions()
         }

         setAllDialogsWithoutActions(addManagerPage.read_only_available)
         setIsEditDialogAllowed(addManagerPage.edit_dialog_allowed)
         setIsContactInfoHidden(addManagerPage.hide_client_identity)
         setIsContactsListUnloadAllowed(addManagerPage.unload_contact_list)
      }
   }, [addManagerPage])

   const addAllChannelsPermissions = () => {
      setManagerChannelsAllows(() => {
         return channelList.map((element) => {
            return element.id
         })
      })
   }

   const removeAllChannelsPermissions = () => {
      setManagerChannelsAllows([])
   }

   const toggleAllChannelsPermissions = () => {
      isAllChannelsAllow
         ? removeAllChannelsPermissions()
         : addAllChannelsPermissions()
      setIsAllChannelsAllow(!isAllChannelsAllow)
   }

   const addChannelPermission = (id) => {
      setManagerChannelsAllows((prevState) => {
         return [...prevState, id]
      })
   }

   const deleteChannelPermission = (id) => {
      setManagerChannelsAllows((prevState) => {
         return prevState.filter((el) => {
            return el !== id
         })
      })
   }

   const addAllTagsPermissions = () => {
      setManagerTagAllows(() => {
         return tag.map((element) => {
            return element.id
         })
      })
   }

   const removeAllTagsPermissions = () => {
      setManagerTagAllows([])
   }

   const toggleAllTagsPermissions = () => {
      if (!tag.length) return
      isAllTagsAllow ? removeAllTagsPermissions() : addAllTagsPermissions()
      setIsAllTagsAllow(!isAllTagsAllow)
   }

   const addTagPermission = (id) => {
      setManagerTagAllows((prevState) => {
         return [...prevState, id]
      })
   }

   const deleteTagPermission = (id) => {
      setManagerTagAllows((prevState) => {
         return prevState.filter((el) => {
            return el !== id
         })
      })
   }

   const addAllStatusPermissions = () => {
      setManagerStatusAllows(() => {
         return status.map((element) => {
            return element.id
         })
      })
   }

   const removeAllStatusPermissions = () => {
      setManagerStatusAllows([])
   }

   const toggleAllStatusesPermissions = () => {
      isAllStatusesAllow
         ? removeAllStatusPermissions()
         : addAllStatusPermissions()
      setIsAllStatusesAllow(!isAllStatusesAllow)
   }

   const addStatusPermission = (id) => {
      setManagerStatusAllows((prevState) => {
         return [...prevState, id]
      })
   }

   const deleteStatusPermission = (id) => {
      setManagerStatusAllows((prevState) => {
         return prevState.filter((el) => {
            return el !== id
         })
      })
   }

   useEffect(() => {
      setIsAllTagsAllow(tag.length > 0 && managerTagsAllows.length === tag.length)
   }, [managerTagsAllows])

   useEffect(() => {
      if (managerStatusAllows.length !== status.length) {
         return setIsAllStatusesAllow(false)
      }
      if (managerStatusAllows.length === status.length) {
         return setIsAllStatusesAllow(true)
      }
   }, [managerStatusAllows])

   useEffect(() => {
      if (managerChannelsAllows.length !== channelList.length) {
         return setIsAllChannelsAllow(false)
      }
      if (managerChannelsAllows.length === channelList.length) {
         return setIsAllChannelsAllow(true)
      }
   }, [managerChannelsAllows])

   useEffect(() => {}, [forceRender])

   const allowTags = () => {
      var bodyFormData = new FormData()
      bodyFormData.append('managerId', managerId)
      bodyFormData.append('accessToAll', isAllTagsAllow)
      bodyFormData.append('tagIds', managerTagsAllows)
      axios({
         method: 'post',
         url: `api/v1/manager/relations-tag`,
         headers: { 'Content-Type': 'multipart/form-data' },
         data: bodyFormData,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const allowStatuses = () => {
      var bodyFormData = new FormData()
      bodyFormData.append('managerId', managerId)
      bodyFormData.append('accessToAll', isAllStatusesAllow)
      bodyFormData.append('dialogStepIds', managerStatusAllows)
      axios({
         method: 'post',
         url: `api/v1/manager/relations-dialog-step`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const allowChannels = () => {
      var bodyFormData = new FormData()
      bodyFormData.append('managerId', managerId)
      bodyFormData.append('accessToAll', isAllChannelsAllow)
      bodyFormData.append('channelIds', managerChannelsAllows)

      axios({
         method: 'post',
         url: `api/v1/manager/relations-channel`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const accessToDialogs = () => {
      axios({
         method: 'post',
         url: `api/v1/manager/set-read-only?managerId=${managerId}&readOnly=${allDialogsWithoutActions}`,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }
    
   const handleUpdateIsContactInfoHidden = async () => {      
      await onUpdateIsContactInfoHidden({ managerId, hideIdentity: isContactInfoHidden })
   }

   const handleUpdateIsContactListUnloadingAllowed = async () => {      
      await onUpdateIsContactListUnloadingAllowed({ managerId, isAllowed: isContactsListUnloadAllowed })
   }

   const saveAllAllows = () => {
      if (tag.length && !managerTagsAllows.length) {
         return showNotification(notificationsData.managerTagsRequired)
      } else if (managerTagsAllows.length) {
         allowTags()
      }
      if (
         managerStatusAllows.length !== 0 &&
         managerChannelsAllows.length !== 0
      ) {
         allowChannels()
         allowStatuses()
         accessToDialogs()
         onUpdateManagerIsAllowedToEditDialog({ id: managerId, editDialog: isEditDialogAllowed })
         handleUpdateIsContactInfoHidden()
         handleUpdateIsContactListUnloadingAllowed()
         window.location.reload(false)
      } else {
         showNotification(notificationsData.managerPermissionsRequired)
      }
   }

   return (
      <div className="manager-mainbar-wrapper" style={{ height: '100vh' }}>
         {addManagerPage !== 0 && (
            <>
               <BasicModal
                  managerId={managerId}
                  addedPhoto={addedPhoto}
                  setAddedPhoto={setAddedPhoto}
                  isVisible={isShowAddPhotoModal}
                  title={'Загрузка новой фотографии'}
                  text={
                     'Вы можете загрузить изображение в формате JPG, PNG или GIF. Размер не более 5 Мбайт.'
                  }
                  cancel={toggleShowAddPhotoModal}
                  buttonTitle={'Добавить файл'}
               />
               <WarningModal
                  isVisible={showWarningDeleteModal}
                  title={'Вы действительно хотите удалить менеджера?'}
                  text={''}
                  yes={() => {
                     handleDeleteManager()
                     toggleShowWarningDeleteModal()
                  }}
                  no={toggleShowWarningDeleteModal}
               />
               <div className="manager-mainbar-user">
                  {addManagerPage.photo === null ? (
                     <svg
                        width="64"
                        height="64"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <circle cx="21" cy="21" r="21" fill="#F2F4F5" />
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M21.0001 13.2917C19.5043 13.2917 18.2917 14.5042 18.2917 16C18.2917 17.4958 19.5043 18.7083 21.0001 18.7083C22.4959 18.7083 23.7084 17.4958 23.7084 16C23.7084 14.5042 22.4959 13.2917 21.0001 13.2917ZM17.0417 16C17.0417 13.8139 18.814 12.0417 21.0001 12.0417C23.1862 12.0417 24.9584 13.8139 24.9584 16C24.9584 18.1861 23.1862 19.9583 21.0001 19.9583C18.814 19.9583 17.0417 18.1861 17.0417 16Z"
                           fill="#40B7D9"
                        />
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M12.875 29.3333C12.875 24.846 16.5127 21.2083 21 21.2083C25.4873 21.2083 29.125 24.846 29.125 29.3333C29.125 29.6785 28.8452 29.9583 28.5 29.9583C28.1548 29.9583 27.875 29.6785 27.875 29.3333C27.875 25.5364 24.7969 22.4583 21 22.4583C17.2031 22.4583 14.125 25.5364 14.125 29.3333C14.125 29.6785 13.8452 29.9583 13.5 29.9583C13.1548 29.9583 12.875 29.6785 12.875 29.3333Z"
                           fill="#40B7D9"
                        />
                     </svg>
                  ) : (
                     <img
                        className="manager-mainbar-user-photo"
                        src={addManagerPage.photo.url}
                     />
                  )}

                  <button
                     onClick={toggleShowAddPhotoModal}
                     className="manager-edit-photo-button"
                  >
                     <svg
                        width="16px"
                        height="16px"
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M1.60449 12.75C1.60449 12.5084 1.80037 12.3125 2.04199 12.3125H12.542C12.7836 12.3125 12.9795 12.5084 12.9795 12.75C12.9795 12.9916 12.7836 13.1875 12.542 13.1875H2.04199C1.80037 13.1875 1.60449 12.9916 1.60449 12.75Z"
                        />
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M9.244 1.8125C9.36009 1.81248 9.47143 1.8586 9.55351 1.94071L11.6841 4.07224C11.8549 4.24308 11.8549 4.51998 11.6841 4.69082L5.65162 10.726C5.56957 10.808 5.45826 10.8542 5.34219 10.8542H3.20801C2.96638 10.8542 2.77051 10.6583 2.77051 10.4167V8.2933C2.77051 8.17736 2.81653 8.06616 2.89846 7.98413L8.93453 1.94083C9.01658 1.85869 9.1279 1.81252 9.244 1.8125ZM9.2442 2.86897L3.64551 8.47437V9.97917H5.16092L10.7561 4.38153L9.2442 2.86897Z"
                        />
                     </svg>
                  </button>
                  <div className='manager-mainbar-user__info'>
                     <span className="manager-mainbar-user_name">
                        {managerUsername}
                     </span>
                     <span className={`manager-mainbar-user__status ${isManagerActive ? 'manager-mainbar-user__status_active' : ''}`}>
                        {isManagerActive ? 'Активный' : 'Неактивный'}
                     </span>
                  </div>
               </div>
               <div className="manager-mainbar-page-block">
                  <button
                     className={
                        currentManagerPage === 1
                           ? 'manager-mainbar-page-button-active'
                           : 'manager-mainbar-page-button'
                     }
                     onClick={() => toggleManagerPage(1)}
                  >
                     Основная информация
                  </button>
                  <button
                     className={
                        currentManagerPage === 2
                           ? 'manager-mainbar-page-button-active'
                           : 'manager-mainbar-page-button'
                     }
                     onClick={() => toggleManagerPage(2)}
                  >
                     Разрешения
                  </button>
               </div>
               <hr></hr>
               {currentManagerPage === 1 && (
                  <>
                     <div className="manager-mainbar-info-wrapper">
                        <EditManagerForm
                           managerData={addManagerPage}
                           onSubmit={handleEditManagerFormSubmit}
                           onStatusChange={handleToggleManagerActivation}
                           onDelete={handleDeleteManager}
                        />
                     </div>
                  </>
               )}
               {currentManagerPage === 2 && (
                  <>
                     <NotificationModal
                        isVisible={notification.isVisible}
                        title={notification.title}
                        text={notification.text}
                        ok={closeNotification}
                     />
                     <div className="manager-permission-control">
                        <div className="manager-permission-control_list-block">
                           <ManagerPermissionList
                              title={'Каналы'}
                              isAllAllow={isAllChannelsAllow}
                              addAllPermissions={addAllChannelsPermissions}
                              removeAllPermissions={
                                 removeAllChannelsPermissions
                              }
                              toggleAllPermissions={
                                 toggleAllChannelsPermissions
                              }
                              elements={channelList.filter(
                                 (channel) => channel.status === 'active'
                              )}
                              permissions={managerChannelsAllows}
                              addPermission={addChannelPermission}
                              deletePermission={deleteChannelPermission}
                           />
                           <hr></hr>
                           <ManagerPermissionList
                              title={'Теги'}
                              isAllAllow={isAllTagsAllow}
                              addAllPermissions={addAllTagsPermissions}
                              removeAllPermissions={removeAllTagsPermissions}
                              toggleAllPermissions={toggleAllTagsPermissions}
                              elements={tag}
                              permissions={managerTagsAllows}
                              addPermission={addTagPermission}
                              deletePermission={deleteTagPermission}
                           />
                           <hr></hr>
                           <ManagerPermissionList
                              title={'Статусы'}
                              isAllAllow={isAllStatusesAllow}
                              addAllPermissions={addAllStatusPermissions}
                              removeAllPermissions={removeAllStatusPermissions}
                              toggleAllPermissions={
                                 toggleAllStatusesPermissions
                              }
                              elements={status}
                              permissions={managerStatusAllows}
                              addPermission={addStatusPermission}
                              deletePermission={deleteStatusPermission}
                           />
                        </div>
                        <div className='manager-permission__footer-permissions'>
                           <ManagerPermission
                              name='Сохранить доступ к диалогам без возможности
                                 отвечать при смене тегов и статусов в меню
                                 “Диалоги”'
                              isActive={allDialogsWithoutActions}
                              onToggle={toggleAllDialogsWithoutActions}
                           />
                           <ManagerPermission
                              name='Возможность редактировать информацию о диалогах'
                              isActive={isEditDialogAllowed}
                              onToggle={handleToggleIsEditDialogAllowed}
                           />
                           <ManagerPermission
                              name='Просмотр контактной информации клиента'
                              isActive={!isContactInfoHidden}
                              onToggle={handleToggleIsContactInfoHidden}
                           />
                           <ManagerPermission
                              name='Разрешить менеджеру выгружать контакты'
                              isActive={isContactsListUnloadAllowed}
                              onToggle={handleToggleIsContactsListUnloadAllowed}
                           />
                        </div>
                        <div className="mainbar-manager-button-block">
                           <button
                              className="mainbar-add-manager-form-button"
                              onClick={saveAllAllows}
                           >
                              Сохранить
                           </button>
                        </div>
                     </div>
                  </>
               )}
            </>
         )}
      </div>
   )
}

export default ManagerMainbar
