import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './managerSidebar.css'
import { managerStatuses } from '../../utils/consts'
import { SidebarHeader } from '../sidebar/Sidebar'
import AddManager from '../modals/AddManager'
import NotificationModal from '../modals/NotificationModal'

const ManagerCard = ({ cardInfo, setManagerCardClick, setAddManagerPage }) => {
   const isManagerActive = cardInfo.status === managerStatuses.active

   return (
      <div
         className="sidebar-contact-card"
         onClick={() => {
            setManagerCardClick(cardInfo)
            setAddManagerPage(cardInfo)
         }}
      >
         <div className="sidebar-card-profile-avatar">
            {!isManagerActive && (<span className='sidebar-card-profile-avatar__status-indicator'></span>)}
            {cardInfo.photo === null ? (
               <svg
                  className="sidebar-card-profile-avatar-pic"
                  width="64"
                  height="64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <circle cx="21" cy="21" r="21" fill="#F2F4F5" />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M21.0001 13.2917C19.5043 13.2917 18.2917 14.5042 18.2917 16C18.2917 17.4958 19.5043 18.7083 21.0001 18.7083C22.4959 18.7083 23.7084 17.4958 23.7084 16C23.7084 14.5042 22.4959 13.2917 21.0001 13.2917ZM17.0417 16C17.0417 13.8139 18.814 12.0417 21.0001 12.0417C23.1862 12.0417 24.9584 13.8139 24.9584 16C24.9584 18.1861 23.1862 19.9583 21.0001 19.9583C18.814 19.9583 17.0417 18.1861 17.0417 16Z"
                     fill="#40B7D9"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M12.875 29.3333C12.875 24.846 16.5127 21.2083 21 21.2083C25.4873 21.2083 29.125 24.846 29.125 29.3333C29.125 29.6785 28.8452 29.9583 28.5 29.9583C28.1548 29.9583 27.875 29.6785 27.875 29.3333C27.875 25.5364 24.7969 22.4583 21 22.4583C17.2031 22.4583 14.125 25.5364 14.125 29.3333C14.125 29.6785 13.8452 29.9583 13.5 29.9583C13.1548 29.9583 12.875 29.6785 12.875 29.3333Z"
                     fill="#40B7D9"
                  />
               </svg>
            ) : (
               <img
                  className="sidebar-card-profile-avatar-pic "
                  src={cardInfo.photo.url}
               />
            )}
         </div>

         <div className="sidebar-card-conversation-details">
            <div className="sidebar-card-conversation-respondent">
               {cardInfo.username}
            </div>
            <div className="sidebar-card-conversation-message">
               {cardInfo.email}
            </div>
         </div>
      </div>
   )
}

const ManagerSidebar = ({
   setAddManagerPage,
   setManagerCardClick,
   setSelectedChannel,
   channelList,
   userName,
   managerSidebarLoader,
   managers,
   managerList,
   setManagerList
}) => {
   const [showAddManagerModal, setShowAddManagerModal] = useState(false)
   const toggleShowAddManageModal = () => {
      setShowAddManagerModal(!showAddManagerModal)
   }
   const [showNotificationModal, setShowNotificationModal] = useState(false)
   const toggleShowNotificationModal = () => {
      setShowNotificationModal(!showNotificationModal)
   }

   const handleInputSearch = (term) => {
      term
         ? setManagerList(
              managers.filter((manager) =>
                 manager.username.toLowerCase().startsWith(term.toLowerCase())
              )
           )
         : setManagerList(managers)
   }
   return (
      <>
         <SidebarHeader
            setSelectedChannel={setSelectedChannel}
            channelList={channelList}
            sidebarToggle={3}
            userName={userName}
         />
         <div
            className="sidebar-header-search-field"
            style={{ marginBottom: '34px', marginTop: '36px' }}
         >
            <img
               className="sidebar-header-search-field-icon"
               src={`/assets/sidebar/search.svg`}
               alt="Lookout"
            />
            <input
               className="sidebar-header-search-field-input"
               type="text"
               placeholder="Поиск"
               onChange={(event) => handleInputSearch(event.target.value)}
            />
         </div>
         <div className="managerSidebar-wrapper">
            <NotificationModal
               title={'Уведомление'}
               text={'Менеджер успешно добавлен.'}
               isVisible={showNotificationModal}
               ok={() => window.location.reload(false)}
            />
            <NotificationModal
               title={'Уведомление'}
               text={'Менеджер успешно добавлен.'}
               isVisible={showNotificationModal}
               ok={() => window.location.reload(false)}
            />
            <AddManager
               isVisible={showAddManagerModal}
               toggleShowAddManageModal={toggleShowAddManageModal}
               managers={managers}
               toggleShowNotificationModal={toggleShowNotificationModal}
            />
            {/*<button*/}
            {/*   onClick={toggleShowAddManageModal}*/}
            {/*   className="sidebar-list-add-contact-button"*/}
            {/*>*/}
            {/*   Добавить менеджера*/}
            {/*</button>*/}
            {managerSidebarLoader ? (
               <div className="manager-sidebar-loader">
                  <div className="lds-ring">
                     <div></div>
                     <div></div>
                     <div></div>
                     <div></div>
                  </div>
               </div>
            ) : managerList && managerList.length > 0 ? (
               managerList.map(
                  (manager, index) => (
                        <ManagerCard
                           key={index}
                           cardInfo={manager}
                           setManagerCardClick={setManagerCardClick}
                           setAddManagerPage={setAddManagerPage}
                        />
                     )
               )
            ) : (
               <>
                  <p>Список пуст</p>
               </>
            )}
         </div>
         <div className="sidebar-list-add-contact-button-block">
            <button
               onClick={toggleShowAddManageModal}
               className="sidebar-list-add-contact-button"
            >
               Добавить менеджера
            </button>
         </div>
      </>
   )
}

export default ManagerSidebar
