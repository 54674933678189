import { CSSTransition } from 'react-transition-group'
import React from 'react'
import './notificationContactModal.css'

const NotificationContactModal = ({ isVisible, title, text, ok, cancel }) => {
   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="notification-contact">
            <div className="notification_wrapper">
               <div className="basic_header">
                  <span className="basic_title">{title}</span>
                  <div className="basic-close">
                     <button onClick={cancel} className="basic-close-button">
                        <img src="/assets/controls/close.svg" alt="" />
                     </button>
                  </div>
               </div>
               <p className="notification_text">{text}</p>
               <div className="notification_button-block">
                  <button className="notification_button" onClick={ok}>
                     Ок
                  </button>
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default NotificationContactModal
